import React from "react";
import Helmet from "react-helmet";
import Bg from "../../assets/wizualizacje/wizualizacja-1.png";
import Bg1 from "../../assets/wizualizacje/wizualizacja-2.png";
import Bg2 from "../../assets/wizualizacje/wizualizacja-3.png";
import Bg3 from "../../assets/wizualizacje/wizualizacja-4.png";
import Bg4 from "../../assets/wizualizacje/wizualizacja-5.png";
import Bg5 from "../../assets/wizualizacje/wizualizacja-6.png";
import Bg6 from "../../assets/wizualizacje/wizualizacja-7.png";
import Bg7 from "../../assets/wizualizacje/wizualizacja-8.png";
import Bg8 from "../../assets/wizualizacje/wizualizacja-9.png";
import Bg9 from "../../assets/wizualizacje/wizualizacja-10.png";
import Bg10 from "../../assets/wizualizacje/wizualizacja-11.png";
import Bg11 from "../../assets/wizualizacje/wizualizacja-12.png";
import Bg12 from "../../assets/wizualizacje/wizualizacja-13.png";
import Bg13 from "../../assets/wizualizacje/wizualizacja-14.png";
import Bg14 from "../../assets/wizualizacje/wizualizacja-15.png";
import Bg15 from "../../assets/wizualizacje/wizualizacja-16.png";
import Bg16 from "../../assets/wizualizacje/wizualizacja-17.png";
import Bg17 from "../../assets/wizualizacje/wizualizacja-18.png";
import Bg18 from "../../assets/wizualizacje/wizualizacja-19.png";
import Bg19 from "../../assets/wizualizacje/wizualizacja-20.png";
import Bg20 from "../../assets/wizualizacje/wizualizacja-21.png";
import Bg21 from "../../assets/wizualizacje/wizualizacja-22.png";
import Bg22 from "../../assets/wizualizacje/wizualizacja-23.png";
import Bg23 from "../../assets/wizualizacje/wizualizacja-24.png";
import Bg24 from "../../assets/wizualizacje/wizualizacja-25.png";
import Bg25 from "../../assets/wizualizacje/wizualizacja-26.png";
import Bg26 from "../../assets/wizualizacje/wizualizacja-27.png";
import Bg27 from "../../assets/wizualizacje/wizualizacja-28.png";
import Bg28 from "../../assets/wizualizacje/wizualizacja-29.png";
import Bg29 from "../../assets/wizualizacje/wizualizacja-30.png";
import Bg30 from "../../assets/wizualizacje/wizualizacja-31.png";
import Bg31 from "../../assets/wizualizacje/wizualizacja-32.png";
import Bg32 from "../../assets/wizualizacje/wizualizacja-33.png";
import Bg33 from "../../assets/wizualizacje/wizualizacja-34.png";
import Bg34 from "../../assets/wizualizacje/wizualizacja-35.png";
import Bg35 from "../../assets/wizualizacje/wizualizacja-36.png";
import Bg36 from "../../assets/wizualizacje/wizualizacja-37.png";
import Bg37 from "../../assets/wizualizacje/wizualizacja-38.png";
// import Bg38 from "../../assets/wizualizacje/wizualizacja-39.png";
import Bg39 from "../../assets/wizualizacje/wizualizacja-40.png";
import Bg40 from "../../assets/wizualizacje/wizualizacja-41.png";
import Bg41 from "../../assets/wizualizacje/wizualizacja-42.png";
import Bg42 from "../../assets/wizualizacje/wizualizacja-43.png";
import Bg43 from "../../assets/wizualizacje/wizualizacja-44.png";
import Bg44 from "../../assets/wizualizacje/wizualizacja-45.png";
import Bg45 from "../../assets/wizualizacje/wizualizacja-46.png";
import Bg46 from "../../assets/wizualizacje/wizualizacja-47.png";
import Bg47 from "../../assets/wizualizacje/wizualizacja-48.png";
import Bg48 from "../../assets/wizualizacje/wizualizacja-49.png";
import Bg49 from "../../assets/wizualizacje/wizualizacja-50.png";
import Bg50 from "../../assets/wizualizacje/wizualizacja-51.png";
import Bg51 from "../../assets/wizualizacje/wizualizacja-52.png";
import Bg52 from "../../assets/wizualizacje/wizualizacja-53.png";
import Bg53 from "../../assets/wizualizacje/wizualizacja-54.png";
import Bg54 from "../../assets/wizualizacje/wizualizacja-55.png";
import Bg55 from "../../assets/wizualizacje/wizualizacja-56.png";
import Bg56 from "../../assets/wizualizacje/wizualizacja-57.png";
import Bg57 from "../../assets/wizualizacje/wizualizacja-58.png";
import Bg58 from "../../assets/wizualizacje/wizualizacja-59.png";
import Bg59 from "../../assets/wizualizacje/wizualizacja-60.png";
import Bg60 from "../../assets/wizualizacje/wizualizacja-61.png";
import Bg61 from "../../assets/wizualizacje/wizualizacja-62.png";
import Bg62 from "../../assets/wizualizacje/wizualizacja-63.png";
import Bg63 from "../../assets/wizualizacje/wizualizacja-64.png";
import Bg64 from "../../assets/wizualizacje/wizualizacja-65.png";
import Bg65 from "../../assets/wizualizacje/wizualizacja-66.png";
import Bg66 from "../../assets/wizualizacje/wizualizacja-67.png";
import Bg67 from "../../assets/wizualizacje/wizualizacja-68.png";
import Bg68 from "../../assets/wizualizacje/wizualizacja-69.png";
import Bg69 from "../../assets/wizualizacje/wizualizacja-70.png";
import Bg70 from "../../assets/wizualizacje/wizualizacja-71.png";
import Bg71 from "../../assets/wizualizacje/wizualizacja-72.png";
import Bg72 from "../../assets/wizualizacje/wizualizacja-73.png";
// import Bg73 from "../../assets/wizualizacje/wizualizacja-74.png";
import Bg74 from "../../assets/wizualizacje/wizualizacja-75.png";
import Bg75 from "../../assets/wizualizacje/wizualizacja-76.png";
import Bg76 from "../../assets/wizualizacje/wizualizacja-77.png";
import Bg77 from "../../assets/wizualizacje/wizualizacja-78.png";
import Bg78 from "../../assets/wizualizacje/wizualizacja-79.png";
import Bg79 from "../../assets/wizualizacje/wizualizacja-80.png";
import Bg80 from "../../assets/wizualizacje/wizualizacja-81.png";
import Bg81 from "../../assets/wizualizacje/wizualizacja-82.png";
import Bg82 from "../../assets/wizualizacje/wizualizacja-83.png";
import Bg83 from "../../assets/wizualizacje/wizualizacja-84.png";
import Bg84 from "../../assets/wizualizacje/wizualizacja-85.png";
import Bg85 from "../../assets/wizualizacje/wizualizacja-86.png";
import Bg86 from "../../assets/wizualizacje/wizualizacja-87.png";
import Bg87 from "../../assets/wizualizacje/wizualizacja-88.png";
import Bg88 from "../../assets/wizualizacje/wizualizacja-89.png";
import Bg89 from "../../assets/wizualizacje/wizualizacja-90.png";
import Bg90 from "../../assets/wizualizacje/wizualizacja-91.png";
import Bg91 from "../../assets/wizualizacje/wizualizacja-92.png";
import Bg92 from "../../assets/wizualizacje/wizualizacja-93.png";
import Bg93 from "../../assets/wizualizacje/wizualizacja-94.png";
// import Bg94 from "../../assets/wizualizacje/wizualizacja-95.png";
import Bg95 from "../../assets/wizualizacje/wizualizacja-96.png";
import Bg96 from "../../assets/wizualizacje/wizualizacja-97.png";
import Bg97 from "../../assets/wizualizacje/wizualizacja-98.png";
import Bg98 from "../../assets/wizualizacje/wizualizacja-99.png";
import Bg99 from "../../assets/wizualizacje/wizualizacja-100.png";
import Bg100 from "../../assets/wizualizacje/wizualizacja-101.png";
import Bg101 from "../../assets/wizualizacje/wizualizacja-102.png";
import Bg102 from "../../assets/wizualizacje/wizualizacja-103.png";
import Bg103 from "../../assets/wizualizacje/wizualizacja-104.png";
import Bg104 from "../../assets/wizualizacje/wizualizacja-105.png";
import Bg105 from "../../assets/wizualizacje/wizualizacja-106.png";
import Bg106 from "../../assets/wizualizacje/wizualizacja-107.png";
import Bg107 from "../../assets/wizualizacje/wizualizacja-108.png";
import Bg108 from "../../assets/wizualizacje/wizualizacja-109.png";
import Bg109 from "../../assets/wizualizacje/wizualizacja-110.png";
import Bg110 from "../../assets/wizualizacje/wizualizacja-111.png";
import Bg111 from "../../assets/wizualizacje/wizualizacja-112.png";
import Bg112 from "../../assets/wizualizacje/wizualizacja-113.png";
// import Bg113 from "../../assets/wizualizacje/wizualizacja-114.png";
import Bg114 from "../../assets/wizualizacje/wizualizacja-115.png";
// import Bg115 from "../../assets/wizualizacje/wizualizacja-116.png";
import Bg116 from "../../assets/wizualizacje/wizualizacja-117.png";
import Bg117 from "../../assets/wizualizacje/wizualizacja-118.png";
import Bg118 from "../../assets/wizualizacje/wizualizacja-119.png";
import Bg119 from "../../assets/wizualizacje/wizualizacja-120.png";
import Bg120 from "../../assets/wizualizacje/wizualizacja-121.png";
import Bg121 from "../../assets/wizualizacje/wizualizacja-122.png";
import Bg122 from "../../assets/wizualizacje/wizualizacja-123.png";
import Bg123 from "../../assets/wizualizacje/wizualizacja-124.png";
import Bg124 from "../../assets/wizualizacje/wizualizacja-125.png";
import Bg125 from "../../assets/wizualizacje/wizualizacja-126.png";
import Bg126 from "../../assets/wizualizacje/wizualizacja-127.png";
import Bg127 from "../../assets/wizualizacje/wizualizacja-128.png";
import Bg128 from "../../assets/wizualizacje/wizualizacja-129.png";
import Bg129 from "../../assets/wizualizacje/wizualizacja-130.png";
// import Bg130 from "../../assets/wizualizacje/wizualizacja-131.png";
import Bg131 from "../../assets/wizualizacje/wizualizacja-132.png";
import Bg132 from "../../assets/wizualizacje/wizualizacja-133.png";
import Bg133 from "../../assets/wizualizacje/wizualizacja-134.png";
import Bg134 from "../../assets/wizualizacje/wizualizacja-135.png";
import Bg135 from "../../assets/wizualizacje/wizualizacja-136.png";
import Bg136 from "../../assets/wizualizacje/wizualizacja-137.png";
import Bg137 from "../../assets/wizualizacje/wizualizacja-138.png";
import Bg138 from "../../assets/wizualizacje/wizualizacja-139.png";
import Bg139 from "../../assets/wizualizacje/wizualizacja-140.png";
import Bg140 from "../../assets/wizualizacje/wizualizacja-141.png";
import Bg141 from "../../assets/wizualizacje/wizualizacja-142.png";
import Bg142 from "../../assets/wizualizacje/wizualizacja-143.png";
import Bg143 from "../../assets/wizualizacje/wizualizacja-144.png";
import Bg144 from "../../assets/wizualizacje/wizualizacja-145.png";
import Bg145 from "../../assets/wizualizacje/wizualizacja-146.png";
import Bg146 from "../../assets/wizualizacje/wizualizacja-147.png";
import Bg147 from "../../assets/wizualizacje/wizualizacja-148.png";
// import Bg148 from "../../assets/wizualizacje/wizualizacja-149.png";
import Bg149 from "../../assets/wizualizacje/wizualizacja-150.png";
import Bg150 from "../../assets/wizualizacje/wizualizacja-151.png";
import Bg151 from "../../assets/wizualizacje/wizualizacja-152.png";
import Container from "../../components/container/container";
import GalleryItemBlock from "../../components/gallery-block/gallery-item-block";
import GalleryWrapperBlock from "../../components/gallery-block/gallery-wrapper-block";
import HeroBlock from "../../components/hero-block/hero-block";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import "./realizacje-page.scss";

const RealizacjePage: React.FC<any> = () => {

  return (
    <PageWrapper className="realizacje-page">
      <Helmet>
        <title>{'Czas na design'}</title>
      </Helmet>
      <Container>
        <HeroBlock bg={Bg} title={"REALIZACJE"}></HeroBlock>
        <GalleryWrapperBlock nav={["SALON", "KUCHNIA I JADALNIA", "ŁAZIENKI", "POKOJE DZIECIĘCE", "SYPIALNIA", "WIATROŁAP I HOL", "POZOSTAŁE", "ELEWACJE I TARASY"]}>

        <GalleryItemBlock bg={Bg} tag={3} title="">
            {/* Projekt salonu w jasnych barwach - połączenie beżu i jasnych odcieni drewna - z dodatkami w kolorze czarnym. */}
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg1} tag={3} title="">
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg2} tag={3} title="">
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg3} tag={1} title="">
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg47} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg122} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg125} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg4} tag={1} title="">
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg5} tag={3} title="">
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg6} tag={1} title="">
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg7} tag={5} title="">
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg8} tag={1} title="">
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg9} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg10} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg11} tag={5} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg12} tag={5} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg13} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg14} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg15} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg16} tag={6} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg17} tag={5} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg18} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg19} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg20} tag={6} title="">
          </GalleryItemBlock>
          
          <GalleryItemBlock bg={Bg21} tag={6} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg22} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg23} tag={7} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg27} tag={7} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg24} tag={6} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg25} tag={6} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg26} tag={7} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg46} tag={7} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg49} tag={7} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg28} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg29} tag={6} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg30} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg31} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg32} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg33} tag={6} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg34} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg35} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg36} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg37} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg39} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg40} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg41} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg42} tag={5} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg43} tag={5} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg44} tag={5} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg45} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg147} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg51} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg48} tag={8} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg50} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg52} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg53} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg54} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg55} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg56} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg57} tag={5} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg58} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg59} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg60} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg61} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg62} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg63} tag={7} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg64} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg65} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg66} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg71} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg67} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg81} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg131} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg68} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg69} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg80} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg70} tag={5} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg72} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg74} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg75} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg76} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg77} tag={5} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg78} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg79} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg82} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg83} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg84} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg85} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg86} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg87} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg119} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg88} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg89} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg90} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg91} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg92} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg93} tag={3} title="">
          </GalleryItemBlock>

          {/* <GalleryItemBlock bg={Bg94} tag={3} title="">
          </GalleryItemBlock> */}

          <GalleryItemBlock bg={Bg95} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg96} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg97} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg98} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg99} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg100} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg101} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg102} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg103} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg104} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg105} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg106} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg107} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg109} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg111} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg108} tag={4} title="">
          </GalleryItemBlock>

          {/* <GalleryItemBlock bg={Bg110} tag={4} title="">
          </GalleryItemBlock> */}

          <GalleryItemBlock bg={Bg112} tag={4} title="">
          </GalleryItemBlock>

          {/* <GalleryItemBlock bg={Bg113} tag={4} title="">
          </GalleryItemBlock> */}

          <GalleryItemBlock bg={Bg114} tag={3} title="">
          </GalleryItemBlock>

          {/* <GalleryItemBlock bg={Bg115} tag={3} title="">
          </GalleryItemBlock> */}

          <GalleryItemBlock bg={Bg116} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg117} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg118} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg120} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg124} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg121} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg123} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg126} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg127} tag={8} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg128} tag={5} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg129} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg132} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg133} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg134} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg135} tag={6} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg136} tag={6} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg137} tag={7} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg138} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg139} tag={3} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg140} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg141} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg142} tag={1} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg143} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg144} tag={2} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg110} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg145} tag={4} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg146} tag={1} title="">
          </GalleryItemBlock>

          {/* <GalleryItemBlock bg={Bg148} tag={3} title="">
          </GalleryItemBlock> */}

          <GalleryItemBlock bg={Bg150} tag={8} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg151} tag={8} title="">
          </GalleryItemBlock>

          <GalleryItemBlock bg={Bg149} tag={8} title="">
          </GalleryItemBlock>


        </GalleryWrapperBlock>
        <br/>
      </Container>
    </PageWrapper>
  )
}

export default RealizacjePage;