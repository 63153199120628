import React from "react";
import "./oferta-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import Bg from "../../assets/exampleBg.jpg";
import Img from "../../assets/exampleImg.jpg";
import Grid from "../../components/grid/grid";
import HalfImgHalfTextRow from "../../components/halfImgHalfTextRow/halfImgHalfTextRow";
import StandardBlock from "../../components/standard-block/standard-block";
import HeroBlock from "../../components/hero-block/hero-block";
import CtaBlock from "../../components/cta-block/cta-block";
import { RoutePath } from "../../../route-paths";
import Row from "../../components/grid/row";
import Col from "../../components/grid/col";
import CollapsibleBlockStandard from "../../components/collapsible-block-standard/collapsible-block-standard";

const OfertaPage: React.FC<any> = () => {

  const SharedDescription = () => {
    return (
      <StandardBlock>
        <p>
          Jestem Absolwentką studiów pierwszego stopnia wydziału architektury na Politechnice Wrocławskiej ,  które ukończyłam w 2017 r.
          Z pasji do tworzenia czegoś pięknego oraz posiadając wiedzę w zakresie architektury i budownictwa pozyskaną podczas studiów oraz  praktyki zawodowej  , rozpoczęłam w 2020r własną  działalność gospodarczą , pod nazwą firmy  „ CZAS NA DESIGN inż.arch Angelika Grzyb”. Główne zalety  współpracy ze mną to  indywidualne podejście do każdego klienta , niezależnie od wielkości zlecenia.
        </p>
      </StandardBlock>
    )
  }

  return (
    <PageWrapper className="oferta-page">
      <Helmet>
        <title>{'Czas na design'}</title>
      </Helmet>
      <Container>
        <HeroBlock bg={Bg} title={"OFERTA"}></HeroBlock>
        <Grid>
          <div className="hideOnMobile">
            <HalfImgHalfTextRow bg={Img} >
              <SharedDescription />
            </HalfImgHalfTextRow>
          </div>
          <div className="showOnMobile">
            <SharedDescription />
          </div>
          <HalfImgHalfTextRow bg={Img} isReverseMode={true}>
            <StandardBlock>
              <p>
                Jestem Absolwentką studiów pierwszego stopnia wydziału architektury na Politechnice Wrocławskiej ,  które ukończyłam w 2017 r.
                Z pasji do tworzenia czegoś pięknego oraz posiadając wiedzę w zakresie architektury i budownictwa pozyskaną podczas studiów oraz  praktyki zawodowej  , rozpoczęłam w 2020r własną  działalność gospodarczą , pod nazwą firmy  „ CZAS NA DESIGN inż.arch Angelika Grzyb”. Główne zalety  współpracy ze mną to  indywidualne podejście do każdego klienta , niezależnie od wielkości zlecenia.
              </p>
            </StandardBlock>
          </HalfImgHalfTextRow>
        </Grid>
      </Container>

      <Container>
        <Grid>
          <Row>
            <Col size={6}>
              <CollapsibleBlockStandard title="ARANŻACJA WNĘTRZA">
                <p>
                  NA TYM ETAPIE STWORZĘ DLA CIEBIE PROPOZYCJE UKŁADÓW FUNKCJONALNYCH, DOBIORĘ
                  MATERIAŁY I UMEBLOWANIE, WYKONAM WIZUALIZACJE I LISTĘ ZAKUPÓW, JEST TO SZYBSZA FORMA
                  NIŻ PROJEKT KOMPLEKSOWY KTÓRY SZYBKO ZOBRAZUJE TWOJE WNĘTRZE.
                  </p>
                <br />
                <ul>
                  <li>WYWIAD</li>
                  <li>INWENTARYZACJA LUB FORMA ON-LINE</li>
                  <li>PRZEDSTAWIENIE PROPOZYCJI MATERIAŁÓW, SPRZETÓW ITD.</li>
                  <li>WSTĘPNA WIZJA FUNKCJONALNA</li>
                  <li>WIZUALIZACJE</li>
                  <li>KOSZTORYS</li>
                </ul>
              </CollapsibleBlockStandard>
              <CollapsibleBlockStandard title="PROJEKT KOMPLEKSOWY ">
                <ul>
                  <li>WYWIAD</li>
                  <li>INWENTARYZACJA LUB FORMA ON-LINE</li>
                  <li>PRZEDSTAWIENIE PROPOZYCJI DOBORU MATERIAŁÓW, SPRZETÓW ITD.</li>
                  <li>DOKUMENTACJA TECHNICZNA:</li>
                  <ul>
                    <li>RZUT</li>
                    <li>PRZEKROJE</li>
                    <li>WIDOKI</li>
                    <li>OPISY WRAZ Z WYTYCZNYMI DO PROJEKTU</li>
                    <li>KOSZTORYS</li>
                    <li>WIZUALIZACJE</li>
                    <li>PROJEKT W FORMIE PAPIEROWEJ LUB ELEKTORNICZNEJ</li>
                  </ul>
                </ul>
                <br />
                <p>
                  POWYŻSZY PROJEKT UŁATWIA PRACĘ WYKONAWCOM A TAKŻE POMAGA PRZY POCHOPNIE
                  PODEJMOWANYCH DECYZJACH NA BUDOWIE. JEST TO FORMA PROJEKTU BARDZIEJ SZCZEGÓŁOWA I
                  CZASOCHŁONNA DLATEGO KOSZT TAKIEGO PROJEKTU JEST WIEKSZY NIŻ FORMA SAMEJ ARANŻACJI
                  WNĘTRZA.
                </p>
              </CollapsibleBlockStandard>

              <CollapsibleBlockStandard title="WIZUALIZACJE ZEWNETRZNE">
                <p>
                  JEŚLI POTRZEBUJESZ WIZUALIZACJI ZEWNĘTRZNEJ Z DOBOREM MATERIAŁÓW TO ZAPRASZAM DO
                  KONTAKTU W CELU USTALENIA ZAKRESU OPRACOWANIA I OFERTĘ.
                </p>
              </CollapsibleBlockStandard>
            </Col>
            <Col size={6}>
              <CollapsibleBlockStandard title="PRODUCENCI">
                <p>
                  JEŚLI JESTEŚ PRODUCENTEM I POTRZEBUJESZ WIZUALIZACJI DO REKLAMY SWOICH PRODUKTÓW
                  CHĘTNIE STWORZE JE DLA CIEBIE. SKONTAKTUJ SIĘ ZE MNA A UDZIELĘ WIECEJ SZCZEGÓŁÓW
                  DOTYCZĄCEJ OFERTY.
                </p>
              </CollapsibleBlockStandard>

              <CollapsibleBlockStandard title="KONSULTACJA">
                <p>
                  JEST TO DOBRA OPCJA DLA KOGOŚ KTO NIE MA CZASU NA PROJEKT, KTÓRY JEST BARDZIEJ
                  CZASOCHŁONNY NIŻ KONSULTACJA Z ACRHITEKTEM. DZIĘKI KONSULTACJI ROZWIĄŻEMY TWOJE
                  WĄTPLIWOŚCI, POMOGE Ci ROZWIĄZAĆ PROBLEMU FUNCJONALNE CZY POMOGĘ Z OGROMEM
                  INNYCH DYLEMATÓW ZWIAZANYCH Z URZĄDZANIEM WNĘTRZ
                </p>
              </CollapsibleBlockStandard>

              <CollapsibleBlockStandard title="CENNIK">
                <p>
                  NIE MA OKREŚLONEJ CENY ZA M2 GDYŻ NA WARTOŚĆ OFERTY WPŁYW MAJĄ CZYNNIKI TAKIE JAK
                  ZAKRES PRACY A TAKŻE CZY MA BYĆ TO PROJEKT KOMPLEKSOWY CZY ARANŻACJA WNĘTRZ.
                  <br />
                  <br />
                  DO KAŻDEGO PROJEKTU PODCHODZĘ INDYWIDUALNIE DLATEGO TEŻ KAŻDY PROJEKT WYCENIANY
                  JEST INDYWIDUALNIE!
                </p>
              </CollapsibleBlockStandard>
            </Col>
          </Row>
        </Grid>

        <CtaBlock to={RoutePath.KONTAKT}>SKONTAKTUJ SIĘ ZE MNĄ</CtaBlock>
      </Container >
    </PageWrapper >
  )
}

export default OfertaPage;