/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useRef, useState } from "react";
import "./collapsible-block-standard.scss";
import { Collapse } from 'react-collapse';
import ArrowDownIcon from './../../assets/arrowDown.svg';

interface Props {
  title: string,
  children: React.ReactNode;
  isOpenInit?: boolean;
  isSplited?: boolean
}

const CollapsibleBlockStandard: React.FC<Props> = (props: Props) => {
  const [isOpen, setIsOpen] = useState(props.isOpenInit ? true : false)
  const { title, children } = props;
  const ref = useRef<any>();

  const handleClickTitle = () => {
    if (!isOpen) {
      // handleScrollToSection(ref, 110, true)
    }
    setIsOpen(!isOpen);
  }

  return (
    <div ref={ref} className="collapsible-block-standard">
      <div className="collapsible-block__title" onClick={() => handleClickTitle()}>
        <div className="offsetCol offsetCol-left hideOnMobile"></div>
        {title}
        <div className="offsetCol offsetCol-right">
          <img className={`imgIcon${isOpen ? " isOpen" : ""}`} src={ArrowDownIcon} alt="arrow" />
        </div>
      </div>
      <Collapse isOpened={isOpen}>
        <div className="collapsible-block__content">
          {children}
        </div>
      </Collapse>
    </div>
  )
}

export default CollapsibleBlockStandard;