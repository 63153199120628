import React from "react";
import "./prefooter.scss";

interface Props {
}

const PreFooter: React.FC<Props> = (props: Props) => {

  return (
    <>
      {/* <section className="prefooter" >
        <Container>
          <div className="rowInfo">
            <TopLink to={RoutePath.OMNIE}>
              <p>O MNIE</p>
            </TopLink>
            <TopLink to={RoutePath.PROJEKTYWNETRZ}>
              <p>PROJEKTY WNĘTRZ</p>
            </TopLink>
            <TopLink to={RoutePath.PROJEKTYTECHNICZNE}>
              <p>PROJEKTY TECHNICZNE</p>
            </TopLink>
            <TopLink to={RoutePath.REALIZACJE}>
              <p>REALIZACJE</p>
            </TopLink>
          </div>
        </Container>
      </section> */}
    </>
  )
}

export default PreFooter;