import React from "react";
import "./process-section.scss";
import Container from "../../components/container/container";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";
import Col from "../../components/grid/col";

const ProcessSection: React.FC<any> = () => {
  const ProcessContent = () => {
    return (
      <div className="process-block">
        <div className="title">PROCES PROJEKTOWY</div>
        <Grid>
          <Row>
            <Col size={[2, 3, 4, 12]} className="process-step">
              <div className="numBlock">1</div>
              <div className="description">
                wywiad
                <br className="hideOnMobile" /> z&nbsp;klientem
              </div>
            </Col>
            <Col size={[2, 3, 4, 12]} className="process-step">
              <div className="numBlock">2</div>
              <div className="description">
                układ
                <br className="hideOnMobile" /> funkcjonalny
              </div>
            </Col>
            <Col size={[2, 3, 4, 12]} className="process-step">
              <div className="numBlock">3</div>
              <div className="description">
                wstępne
                <br className="hideOnMobile" /> koncepcje
              </div>
            </Col>
            <Col size={[2, 3, 4, 12]} className="process-step">
              <div className="numBlock">4</div>
              <div className="description">
                ocena
                <br className="hideOnMobile" /> projektu
              </div>
            </Col>
            <Col size={[2, 3, 4, 12]} className="process-step">
              <div className="numBlock">5</div>
              <div className="description">
                korekty
                <br className="hideOnMobile" /> koncepcji
              </div>
            </Col>
            <Col size={[2, 3, 4, 12]} className="process-step">
              <div className="numBlock">6</div>
              <div className="description">
                wizualizacje
                <br className="hideOnMobile" />
              </div>
            </Col>
            <Col size={[2, 3, 4, 12]} className="process-step">
              <div className="numBlock">7</div>
              <div className="description">
                kosztorys
                <br className="hideOnMobile" />
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <section className="process-section hideOnMobile">
        <Container>
          <ProcessContent />
        </Container>
      </section>
      <section className="process-section showOnMobile">
        <br />
        <ProcessContent />
        <br />
      </section>
    </>
  );
};

export default ProcessSection;
