import React from 'react';

export const CookiePolicy = () => {
  return (
    <div className="policy">
      <p><strong>Obowiązek informacyjny administratora danych na podstawie art. 13 i 14 RODO* w zakresie przetwarzania danych osobowych</strong></p>
      <p>&nbsp;</p>
      <p>Niniejszym jako Administrator przetwarzający Pani/Pana dane osobowe, spełniając obowiązek informacyjny z art. 13 i 14 rozporządzenia Parlamentu Europejskiego i Rady UE 2016/679 z dnia 27 kwietnia 2016 r. informuję iż:</p>
      <ol>
        <li>Administratorem Pani/Pana danych osobowych jest Angelika Grzyb prowadząca działalność gospodarczą pod nazwą  CZAS NA DESIGN Angelika Grzyb z siedzibą w Oleśnicy (56-400) przy ul. Południowej 1, NIP 7511752957. Z Administratorem można skontaktować się korespondencyjnie na podany powyżej adres siedziby lub w drodze korespondencji e-mail na adres: biuro@czasnadesign.pl</li>
        <li>Pani/Pana dane osobowe przetwarzane będę na podstawie art. 6 ust. 1 lit. a, b lub f rozporządzenia Parlamentu Europejskiego i Rady UE 2016/679 z dnia 27 kwietnia 2016 r. w celu prawidłowej realizacji zawartych um&oacute;w, celach marketingowych, wystawiania dokument&oacute;w księgowych, archiwizacji, dochodzenia i windykacji zaległych należności lub ewentualnych postępowań sądowych.</li>
        <li>Odbiorcą Pani/Pana danych osobowych będą podmioty wsp&oacute;łpracujące z Administratorem lub upoważnione na podstawie obowiązujących przepis&oacute;w prawa.</li>
        <li>Pani/Pana dane przechowywane będą przez okres niezbędny do realizacji cel&oacute;w Administratora.</li>
        <li>Przysługuje Pani/Panu prawo dostępu do treści swoich danych, ich sprostowania (poprawiania), usunięcia, ograniczenia przetwarzania, przenoszenia, prawo do wniesienia sprzeciwu wobec przetwarzania danych.</li>
        <li>Przysługuje Pan/Pani prawo wniesienia skargi do organu nadzorczego (Prezesa Urzędu Ochrony Danych Osobowych) gdy Pan/Pani uzna, iż przetwarzanie Pana/Pani danych osobowych jest niezgodne z prawem.</li>
        <li>Administrator stosuje odpowiednie i właściwe zabezpieczenia w celu ochrony Pana/Pani danych, przysługuje Pani/Panu prawo uzyskania kopii swoich danych, kt&oacute;re mogą zostać udostępnione w siedzibie Administratora.</li>
        <li>Pani/Pana dane osobowe nie będą podlegać zautomatyzowanemu przetwarzaniu, w tym profilowaniu.</li>
        <li>Pani/Pana dane osobowe nie będą przekazywane do państwa trzeciego lub organizacji międzynarodowych.</li>
      </ol>
      <p>&nbsp;&nbsp;</p>
      <p>*&nbsp;<strong>RODO</strong>&nbsp;&ndash; rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (og&oacute;lne rozporządzenie o ochronie danych).</p>
      <p>&nbsp;</p>
    </div>
  )
}