import React from "react";
import "./contact-section.scss";
import FbIcon from "../../assets/fb.svg";

import Container from "../../components/container/container";
import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";
import HalfHeroBlock from "../../components/half-hero-block/half-hero-block";
import StandardBlock from "../../components/standard-block/standard-block";

const ContactSection: React.FC<any> = () => {

  return (
    <section className="contact-section">
      <Container>
        <div className="whitePart">
          <StandardBlock>
            <div className="title">KONTAKT</div>
            <br />
            <br />
            <div className="subtitle">ADRES:</div>
            <p>ul. Południowa 1<br />56-400 Oleśnica</p>
            <br />
            <div className="subtitle">TELEFON KONTAKTOWY:</div>
            <p>+48 535 199 564</p>
            <br />

            <div className="subtitle">ADRES E-MAIL:</div>
            <p>biuro@czasnadesign.pl</p>
            <br />
            <div className="subtitle">ZNAJDŹ MNIE:</div>
            <div className="iconsWrapper">
              <a href="https://www.facebook.com/CNDwnetrza?locale=pl_PL"><img src={FbIcon} alt="" /></a>
              {/* <a href="https://instagram.com"><img src={IgIcon} alt="" /></a> */}
            </div>
            <br />
            <TopLink to={RoutePath.KONTAKT} className={"link"} variant={"dark"}>NAPISZ JUŻ TERAZ!</TopLink>
          </StandardBlock>
        </div>
        <HalfHeroBlock variant="dark" align="right">
          POMOGĘ CI<br />ZREALIZOWAĆ<br />TWOJĄ WIZJĘ
        </HalfHeroBlock>
      </Container>
    </section>
  )
}

export default ContactSection;