import React from "react";
import { useDispatch } from "react-redux";
import { generatePath, useHistory } from "react-router-dom";
import { showSlideOverlay } from "../../redux/callbacksState/callbacksState.actions";
import "./gallery-block.scss";

export interface GalleryItemBlockProps {
  children?: React.ReactNode;
  activeTag?: number | undefined;
  bg: string;
  title: string;
  tag: number | undefined;
  isHiddenOnMobile?: boolean;
  to?: string;
}

const GalleryItemBlock: React.FC<GalleryItemBlockProps> = (
  props: GalleryItemBlockProps
) => {
  const {
    children,
    bg,
    title,
    activeTag,
    tag,
    isHiddenOnMobile = false,
    to,
  } = props;
  let history = useHistory();

  const dispatch = useDispatch();

  const pushToPage = () => {
    if (to) {
      history.push(generatePath(to));
      dispatch(showSlideOverlay(props.to as string));
    }
  };

  const MobileBlock = () => {
    return (
      <div
        onClick={() => pushToPage()}
        className={`gallery-item-block${
          to ? " isClickable" : ""
        } showOnMobile gridGallery_item${
          activeTag === tag || activeTag === undefined
            ? " isVisible isMaximalized"
            : " isHidden isMinimalized"
        }`}
      >
        <div
          className="bgBlur"
          style={{ backgroundImage: `url("${bg}")` }}
        ></div>
        <div className="title">{title}</div>
        <div className="description">{children}</div>
      </div>
    );
  };
  return (
    <>
      <div
        onClick={() => pushToPage()}
        className={`gallery-item-block${
          to ? " isClickable" : ""
        } hideOnMobile gridGallery_item${
          activeTag === tag || activeTag === undefined
            ? " isVisible isMaximalized"
            : " isHidden isMinimalized"
        }`}
        style={{ backgroundImage: `url("${bg}")` }}
      >
        <div className="bgBlur"></div>
        <div className="title">{title}</div>
        <div className="description">{children}</div>
      </div>
      <div className="showOnMobile"></div>
      {isHiddenOnMobile ? <></> : <MobileBlock />}
    </>
  );
};

export default GalleryItemBlock;
