import React, { useState } from "react";
import "./gallery-block.scss";
import { GalleryItemBlockProps } from "./gallery-item-block";

interface Props {
  children: Array<React.ReactElement<GalleryItemBlockProps>>;
  nav: Array<string>;
}

const GalleryWrapperBlock: React.FC<Props> = (props: Props) => {
  const { children, nav } = props;
  const [activeTag, setActiveFilter] = useState(
    undefined as undefined | number
  );
  const [activeHideAllAnimation, setActiveHideAllAnimation] = useState(false);

  const setActiveFilterHandler = (newFilter: number) => {
    setActiveHideAllAnimation(true);
    setTimeout(() => {
      setActiveHideAllAnimation(false);
      if (newFilter === activeTag) setActiveFilter(undefined);
      else setActiveFilter(newFilter);
    }, 500);
  };

  return (
    <>
      <div className="gridNav">
        {nav
          ? nav.map((navItem: React.ReactNode, index: number) => {
              return (
                <div
                  key={index}
                  className="gridNav_item"
                  onClick={() => setActiveFilterHandler(index + 1)}
                >
                  {navItem}
                </div>
              );
            })
          : null}
      </div>
      <div className={`gridGallery${activeHideAllAnimation ? " hideAll" : ""}`}>
        {React.Children.map(
          children,
          (
            galleryItemBlock: React.ReactElement<GalleryItemBlockProps>,
            index: Number
          ) => (
            <React.Fragment key={index as any}>
              {React.cloneElement(galleryItemBlock, {
                activeTag: activeTag,
              })}
            </React.Fragment>
          )
        )}
      </div>
    </>
  );
};

export default GalleryWrapperBlock;
