import React from "react";
import { RoutePath } from "../../../route-paths";
import Bg4 from "../../assets/jadalnia-1-min.png";
import Bg2 from "../../assets/kuchnia-1-min.png";
import Bg3 from "../../assets/kuchnia-2-min.png";
import Bg5 from "../../assets/lazienka-1-min.png";
import Bg6 from "../../assets/lazienka-2-min.png";
import Bg7 from "../../assets/pokoje-1-min.png";
import Bg from "../../assets/salon-1-min.png";
import Bg1 from "../../assets/salon-2-min.png";
import Bg8 from "../../assets/sypialnia-1-min.png";
import Bg16 from "../../assets/wizualizacje/wizualizacja-17.png";
import Bg63 from "../../assets/wizualizacje/wizualizacja-64.png";
import Bg130 from "../../assets/wizualizacje/wizualizacja-131.png";
import Container from "../../components/container/container";
import GalleryItemBlock from "../../components/gallery-block/gallery-item-block";
import GalleryWrapperBlock from "../../components/gallery-block/gallery-wrapper-block";
import "./gallery-section.scss";
import StandardBlock from "../../components/standard-block/standard-block";
import TopLink from "../../components/top-link/top-link";

const GallerySection: React.FC<any> = () => {
  return (
    <section className="gallery-section">
      <Container>
        <GalleryWrapperBlock
          nav={[
            "SALON",
            "KUCHNIA I JADALNIA",
            "ŁAZIENKI",
            "POKOJE DZIECIĘCE",
            "SYPIALNIA",
            "WIATROŁAP I HOL",
            "POZOSTAŁE",
            "ELEWACJE I TARASY",
          ]}
        >
          <GalleryItemBlock
            bg={Bg}
            tag={1}
            title="Salon z jadalnią"
            to={RoutePath.REALIZACJE}
          >
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock
            bg={Bg1}
            tag={1}
            title="Salon ze schodami"
            to={RoutePath.REALIZACJE}
          >
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock
            bg={Bg2}
            tag={2}
            title="Kuchnia wraz z jadalnią"
            to={RoutePath.REALIZACJE}
          >
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock
            bg={Bg3}
            tag={2}
            title="Widok z salonu na kuchnię i schody"
            to={RoutePath.REALIZACJE}
          >
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock
            bg={Bg4}
            tag={2}
            title="Jadalnia z witryną"
            to={RoutePath.REALIZACJE}
          >
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock
            bg={Bg5}
            tag={3}
            title="Nowoczesna, mała łazienka"
            to={RoutePath.REALIZACJE}
          >
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock
            bg={Bg6}
            tag={3}
            title="Duża, funkcjonalna łazienka"
            to={RoutePath.REALIZACJE}
          >
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock
            bg={Bg7}
            tag={4}
            title="Kolorowy pokój dla dziecka"
            to={RoutePath.REALIZACJE}
          >
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock
            bg={Bg8}
            tag={5}
            title="Przytulna sypialnia"
            to={RoutePath.REALIZACJE}
          >
            {/* 1 fuly falscc urate, or mi slet her cers aion, matebout important events, topics, e diw theive or co nfuse othor cistr ibution of Crriae, inalonte nt that is, in ourla our w hich state tha does not allow thclarify our which state tha does not a llo rcum sta nces */}
          </GalleryItemBlock>

          <GalleryItemBlock
            bg={Bg16}
            tag={6}
            title="Minimalistyczne wejście do domu"
            to={RoutePath.REALIZACJE}
          ></GalleryItemBlock>

          <GalleryItemBlock
            bg={Bg63}
            tag={7}
            title="Nowoczesne biuro wraz z poczekalnią"
            to={RoutePath.REALIZACJE}
          ></GalleryItemBlock>

          <GalleryItemBlock
            bg={Bg130}
            tag={8}
            title="Elewacja wraz z tarasem i antresolą"
            to={RoutePath.REALIZACJE}
          ></GalleryItemBlock>
        </GalleryWrapperBlock>
        <br />
        <br />
        <br />
        <br />
        <StandardBlock className="center">
          <TopLink to={RoutePath.REALIZACJE}>
            Zobacz pozostałe realizacje
          </TopLink>
        </StandardBlock>
      </Container>
    </section>
  );
};

export default GallerySection;
