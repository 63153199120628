import React from "react";
import "./top-link.scss";
import { useDispatch } from "react-redux";
import { showSlideOverlay } from "../../redux/callbacksState/callbacksState.actions";

interface Props {
  to: string,
  children: React.ReactNode | string,
  className?: string,
  ref?: any,
  variant?: "dark" | "white"
}

const TopLink: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()

  const slideAndScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(showSlideOverlay(props.to as string))
    // setTimeout(() => {
    //   window.scrollTo(0, 0)
    // }, 200);
  }

  return (
    <a href={props.to} onClick={(e) => slideAndScroll(e)} className={`topLinkWrapper${props.className ? " " + props.className : ""}${props.variant ? " " + props.variant : " white"}`}>{props.children}</a>
  )
}

export default TopLink;
