const CallbacksStateTypes = {
  TOAST_FAILEDTOFETCH_ACTIVATED: 'TOAST_FAILEDTOFETCH_ACTIVATED',
  HIDE_SLIDE_OVERLAY_SUCCESS: 'HIDE_SLIDE_OVERLAY_SUCCESS',
  SHOW_SLIDE_OVERLAY_SUCCESS: 'SHOW_SLIDE_OVERLAY_SUCCESS',
  SET_NAVBARBAR: 'SET_NAVBARBAR',
  SET_NAVBAROPEN: 'SET_NAVBAROPEN',
  
  SET_SEARCHPHRASE: 'SET_SEARCHPHRASE',
};

export default CallbacksStateTypes;