import React, { } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { select_is_navbarOpenActive } from "../../redux/callbacksState/callbacksState.selectors";
import "./map-section.scss";

interface Props {
  maxWidth?: number
}

const FullMapSection: React.FC<Props> = (props: Props) => {
  const { maxWidth } = props;
  const is_navbarOpenActive = useSelector(select_is_navbarOpenActive, shallowEqual);

  return (
    <section className="map-section" style={{ maxWidth: maxWidth ? maxWidth : '100%', pointerEvents: is_navbarOpenActive ? "none" : "initial" }}>
      <iframe className="map" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2500.03294143643!2d17.377954415758687!3d51.20004467958581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fe2e4c62ffe25%3A0x5deecf2ce314e50b!2sPo%C5%82udniowa%201%2C%2056-400%20Ole%C5%9Bnica!5e0!3m2!1spl!2spl!4v1625230638324!5m2!1spl!2spl"frameBorder="0" style={{ border: 0 }} allowFullScreen={false} aria-hidden="false" tabIndex={0}></iframe>
    </section>
  )
}

export default FullMapSection;