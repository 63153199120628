export class RoutePath {
  [key: string]: any;
  static RULES: string = '/warunki-uzytkowania';
  static OMNIE: string = '/o-mnie';
  static PROJEKTYWNETRZ: string = '/projekty-wnetrz';
  static PROJEKTYTECHNICZNE: string = '/projekty-techniczne';
  static PROCESPROJEKTOWY: string = '/proces-projektowy';
  static REALIZACJE: string = '/realizacje';
  static KONTAKT: string = '/kontakt';
  static OFERTA: string = '/oferta';
  static WYCENA: string = '/wycen-projekt';

  
  static HOME: string = '/';
}