import React from "react";
import "./standard-block.scss";

interface Props {
  children: React.ReactNode;
  variant?: "dark" | "white";
  className?: string;
}

const StandardBlock: React.FC<Props> = (props: Props) => {
  const { children, variant, className } = props;

  return (
    <div
      className={`standard-block ${variant ? variant : "dark"} ${
        className ? className : ""
      }`}
    >
      {children}
    </div>
  );
};

export default StandardBlock;
