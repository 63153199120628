import React from "react";
import "./hero-block.scss";
import ArrowDown from "../../assets/arrowDown.svg";
import CollapsibleBlock from "../collapsible-block/collapsible-block";
import CollapsibleBlocksWrapper from "../collapsible-block/collapsible-blocks-wrapper";
import FullMapSection from "../map-section/map-section";

interface Props {
  children?: React.ReactNode;
  // align?: 'left' | 'center' | 'right';
  bg?: string;
  isCollapsible?: boolean;
  title: string;
}

const HeroMapBlock: React.FC<Props> = (props: Props) => {
  const { children, bg, isCollapsible, title } = props;
  return (
    <div className={"heroBlock heroMapBlock"} style={{ backgroundImage: `url("${bg}")` }}>
      <FullMapSection />
      { isCollapsible ?
        <CollapsibleBlocksWrapper>
          <CollapsibleBlock title={title}>
            {children}
          </CollapsibleBlock>
        </CollapsibleBlocksWrapper>
        :
        <>
          <div className="header-title hideOnMobile">
            <img src={ArrowDown} alt="ArrowDown" />
            <span className="title">{title}</span>
            <span className="offset">
            </span>
          </div>
          <div className="header-title showOnMobile">
            <span className="title">{title}</span>
          </div>
          <div className="header-arrowDown showOnMobile">
            <img src={ArrowDown} alt="ArrowDown" />
          </div>

        </>
      }

    </div>
  )
}

export default HeroMapBlock;