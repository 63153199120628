/* eslint-disable jsx-a11y/anchor-has-content */
import React, {  } from "react";
import "./fullImg-block.scss";

interface Props {
  img: string;
  alt?: string;
}

const FullImgBlock: React.FC<Props> = (props: Props) => {
  const {img, alt } = props;

  return (
    <div className={"fullImg-block"}>
      <img src={img} alt={alt?alt:""}/>
    </div>
  )
}

export default FullImgBlock;