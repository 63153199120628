import React, { useEffect, useState } from "react";
import Col from "../grid/col";
import Grid from "../grid/grid";
import Row from "../grid/row";
import BtnCustom from "./btn-custom/btn-custom";
import CheckBox from "./input-checkbox/input-checkbox";
import FormInput from "./input-text/input-text";
import "./contact-form.scss";
import Container from "../container/container";
import { RoutePath } from "../../../route-paths";
import TopLink from "../top-link/top-link";

let timeoutForm: any = undefined;

interface Props {
  title?: string | React.ReactNode;
}

let changeSendBtnStatus: NodeJS.Timeout;

const ContactForm: React.FC<Props> = (props: Props) => {
  const [name, setname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [topic, setTopic] = useState("");
  const [sqm, setSqm] = useState("");
  const [localization, setLocalization] = useState("");

  const [formStatus, setFormStatus] = useState(
    "normal" as "normal" | "pending" | "success" | "fail"
  );
  const [formStatusWatcher, setFormStatusWatcher] = useState(
    "normal" as "normal" | "pending" | "success" | "fail"
  );

  const [isRuleAccepted, setIsRuleAccepted] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setFormStatus("pending");

    var payload = {
      name: name,
      email: email,
      phone: phone,
      message: message,
      topic: topic,
      sqm: sqm,
      localization: localization,
      isRuleAccepted: isRuleAccepted
        ? `Naciskając klawisz WYŚLIJ oświadczam, że zapoznałem/łam się z polityką prywatności strony i jest ona dla mnie zrozumiała.`
        : "NIE",
    };

    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    var result = fetch("send.php", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });

    result
      .then((response) => {
        if (response.ok) {
          setFormStatusWatcher("success");
          setname("");
          setEmail("");
          setPhone("");
          setMessage("");
          setTopic("");
          setSqm("");
          setLocalization("");
          setIsRuleAccepted(false);
        } else {
          setFormStatusWatcher("fail");
        }
      })
      .catch((error) => {
        setFormStatusWatcher("fail");
        console.log(error);
      });
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutForm);
    };
  }, []);

  useEffect(() => {
    setFormStatus(formStatusWatcher);
    if (changeSendBtnStatus) clearTimeout(changeSendBtnStatus);
    changeSendBtnStatus = setTimeout(() => {
      setFormStatus("normal");
    }, 5000);
  }, [formStatusWatcher]);

  return (
    <section className={`contact-form-section`}>
      <Container>
        <div className="title">WYCEŃ SWÓJ PROJEKT</div>
        <div className="subtitle">
          Wypełnij poniższy formularz i otrzymaj mailowo całkowity koszt, zakres
          oraz termin realizacji projektu.​​​​​​​
        </div>
        <form className={`contact-form`} onSubmit={handleSubmit}>
          <Grid>
            {props ? (
              <Row gap={16}>
                <Col size={12}>{props.title}</Col>
              </Row>
            ) : null}
            <Row gap={16}>
              <Col size={[6, 6, 12]}>
                <FormInput
                  name="name"
                  type="text"
                  label="Imię i nazwisko *"
                  value={name}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setname(e.target.value)
                  }
                  required
                />
              </Col>
              <Col size={[6, 6, 12]}>
                <FormInput
                  name="topic"
                  type="text"
                  label="Czego dotyczy projekt *"
                  value={topic}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setTopic(e.target.value)
                  }
                  required
                />
              </Col>
              <Col size={[6, 6, 12]}>
                <FormInput
                  name="sqm"
                  type="text"
                  label="Jaka jest powierzchnia *"
                  value={sqm}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setSqm(e.target.value)
                  }
                  required
                />
              </Col>
              <Col size={[6, 6, 12]}>
                <FormInput
                  name="localization"
                  type="text"
                  label="Lokalizacja inwestycji *"
                  value={localization}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setLocalization(e.target.value)
                  }
                  required
                />
              </Col>
              <Col size={[6, 6, 12]}>
                <FormInput
                  name="email"
                  type="email"
                  label="Email *"
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setEmail(e.target.value)
                  }
                  required
                />
              </Col>
              <Col size={[6, 6, 12]}>
                <FormInput
                  name="phone"
                  type="text"
                  label="Telefon kontaktowy *"
                  value={phone}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setPhone(e.target.value)
                  }
                  required
                />
              </Col>
              <Col size={12}>
                <FormInput
                  name="message"
                  type="textarea"
                  label="Dodatkowe informacje *"
                  value={message}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setMessage(e.target.value)
                  }
                  required
                />
              </Col>
              <Col size={12}>
                <CheckBox
                  handleChange={setIsRuleAccepted}
                  isActive={isRuleAccepted}
                >
                  Naciskając klawisz WYŚLIJ oświadczam, że zapoznałem/łam się z <TopLink to={RoutePath.RULES}>polityką prywatności strony</TopLink> i jest ona dla mnie zrozumiała.
                </CheckBox>
              </Col>
              <Col size={12}>
                <BtnCustom
                  type="submit"
                  disabled={formStatus === "pending"}
                  className={
                    formStatus === "success"
                      ? "success"
                      : formStatus === "fail"
                      ? "fail"
                      : ""
                  }
                >
                  {formStatus === "normal" ? "WYŚLIJ" : null}
                  {formStatus === "pending" ? "TRWA WYSYŁANIE" : null}
                  {formStatus === "success" ? "WYSŁANO POMYŚLNIE" : null}
                  {formStatus === "fail"
                    ? "WYSTĄPIŁ PROBLEM PRZY WYSYŁCE"
                    : null}
                </BtnCustom>
              </Col>
            </Row>
          </Grid>
        </form>
      </Container>
    </section>
  );
};

export default ContactForm;
