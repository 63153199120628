import React from "react";
import "./omnie-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import Bg from "../../assets/sypialnia-1-min.png";
import Img from "../../assets/pokoje-1-min.png";
import Grid from "../../components/grid/grid";
import HalfImgHalfTextRow from "../../components/halfImgHalfTextRow/halfImgHalfTextRow";
import StandardBlock from "../../components/standard-block/standard-block";
import HeroBlock from "../../components/hero-block/hero-block";
import CtaBlock from "../../components/cta-block/cta-block";
import { RoutePath } from "../../../route-paths";

const OmniePage: React.FC<any> = () => {

  const SharedDescription = () => {
    return (
      <StandardBlock>
        <p>
          Jestem Absolwentką studiów pierwszego stopnia Wydziału Architektury na Politechnice Wrocławskiej ,  które ukończyłam w 2017 r.
          Z pasji do tworzenia czegoś pięknego oraz posiadając wiedzę w zakresie architektury i budownictwa pozyskaną podczas studiów oraz  praktyki zawodowej  , rozpoczęłam w 2020r własną  działalność gospodarczą , pod nazwą firmy  „ CZAS NA DESIGN inż.arch Angelika Grzyb”. Główne zalety  współpracy ze mną to  indywidualne podejście do każdego klienta , niezależnie od wielkości zlecenia.
        </p>
      </StandardBlock>
    )
  }

  return (
    <PageWrapper className="omnie-page">
      <Helmet>
        <title>{'Czas na design'}</title>
      </Helmet>
      <Container>
        <HeroBlock bg={Bg} title={"O MNIE"}></HeroBlock>
        <Grid>
          <div className="hideOnMobile">
            <HalfImgHalfTextRow bg={Img} >
              <SharedDescription />
            </HalfImgHalfTextRow>
          </div>
          <div className="showOnMobile">
            <StandardBlock>
              <div className="subtitle">
                <strong>
                  ANGELIKA GRZYB
              </strong>
              </div>
              <br />
              <br />
            </StandardBlock>
            <SharedDescription />
          </div>
          {/* <HalfImgHalfTextRow bg={Img} isReverseMode={true}>
            <StandardBlock>
              <p>
                Jestem Absolwentką studiów pierwszego stopnia wydziału architektury na Politechnice Wrocławskiej ,  które ukończyłam w 2017 r.
                Z pasji do tworzenia czegoś pięknego oraz posiadając wiedzę w zakresie architektury i budownictwa pozyskaną podczas studiów oraz  praktyki zawodowej  , rozpoczęłam w 2020r własną  działalność gospodarczą , pod nazwą firmy  „ CZAS NA DESIGN inż.arch Angelika Grzyb”. Główne zalety  współpracy ze mną to  indywidualne podejście do każdego klienta , niezależnie od wielkości zlecenia.
              </p>
            </StandardBlock>
          </HalfImgHalfTextRow> */}
        </Grid>
        <CtaBlock to={RoutePath.KONTAKT}>SKONTAKTUJ SIĘ ZE MNĄ</CtaBlock>
      </Container>
    </PageWrapper>
  )
}

export default OmniePage;