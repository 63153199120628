import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cookiePolicyPopupReducer from './cookiePolicyPopup/cookiePolicyPopup.reducer';

import callbacksStateReducer from './callbacksState/callbacksState.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cookiePolicyPopup']
}

const rootReducer = combineReducers({
  cookiePolicyPopup: cookiePolicyPopupReducer,
  callbacksState: callbacksStateReducer,
})

export default persistReducer(persistConfig, rootReducer);