import React from "react";
import TopLink from "../top-link/top-link";
import "./cta-block.scss";
import ArrowRight from "../../assets/arrowRight.svg";

interface Props {
  children: React.ReactNode;
  to: string;
}

const CtaBlock: React.FC<Props> = (props: Props) => {
  const { children, to } = props;
  return (
    <TopLink to={to} className={'cta-block'}>
      <span className="offset"></span>
      <span className="content">
        {children}
      </span>
      <img src={ArrowRight} alt="ArrowRight" className="arrowRight" />
    </TopLink>
  )
}

export default CtaBlock;