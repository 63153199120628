import React from "react";
import "./home-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Bg from "../../assets/slajd-1.jpg";
import Bg1 from "../../assets/slajd-2.jpg";
import Bg2 from "../../assets/slajd-3.jpg";

import ArrowDown from "../../assets/arrowDown.svg";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";
import GallerySection from "./gallery-section";
import AboutSection from "./about-section";
import ProcessSection from "./process-section";
import ContactSection from "./contact-section";
import StandardBlock from "../../components/standard-block/standard-block";
import ContactForm from "../../components/contact-form/contact-form";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const HomePage: React.FC<any> = () => {
  return (
    <PageWrapper className="home-page">
      <Helmet>
        <title>{"Czas na design"}</title>
      </Helmet>
      <section>
        <Container>
          <div className="hideOnMobile">
            <Swiper
              spaceBetween={16}
              slidesPerView={1}
              navigation={false}
              loop={true}
              direction={"vertical"}
              autoplay={{
                delay: 4000,
              }}
              pagination={{
                el: ".swiper-pagination",
                clickable: true,
              }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              <SwiperSlide>
                <div className="slideContantWrapper">
                  <div
                    className={"sliderBg"}
                    style={{ backgroundImage: `url("${Bg}")` }}
                  ></div>
                  <div className={"sliderContent"}>
                    <div className="corner topCorner"></div>
                    <h2 className={"title"}>
                      ANGELIKA
                      <br />
                      GRZYB
                    </h2>
                    <StandardBlock>
                      <TopLink to={RoutePath.KONTAKT}>skontaktuj się</TopLink>
                    </StandardBlock>
                    <div className="corner botCorner"></div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slideContantWrapper">
                  <div
                    className={"sliderBg"}
                    style={{ backgroundImage: `url("${Bg1}")` }}
                  ></div>
                  <div className={"sliderContent"}>
                    <div className="corner topCorner"></div>
                    <h2 className={"title"}>
                      PROJEKT
                      <br />
                      DLA CIEBIE
                    </h2>
                    <StandardBlock>
                      <TopLink to={RoutePath.WYCENA}>wyceń projekt</TopLink>
                    </StandardBlock>
                    <div className="corner botCorner"></div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slideContantWrapper">
                  <div
                    className={"sliderBg"}
                    style={{ backgroundImage: `url("${Bg2}")` }}
                  ></div>
                  <div className={"sliderContent"}>
                    <div className="corner topCorner"></div>
                    <h2 className={"title"}>
                      SPRAWDŹ
                      <br />
                      MOJE PROJEKTY
                    </h2>
                    <StandardBlock>
                      <TopLink to={RoutePath.REALIZACJE}>realizacje</TopLink>
                    </StandardBlock>
                    <div className="corner botCorner"></div>
                  </div>
                </div>
              </SwiperSlide>
              <div className="swiper-pagination"></div>
            </Swiper>
            <div className={"arrowDownBtn"}>
              <img src={ArrowDown} alt="ArrowDown" />
            </div>
          </div>

          <div className="showOnMobile oneSlideMobile">
            <div className="slideContantWrapper">
              <div
                className={"sliderBg"}
                style={{ backgroundImage: `url("${Bg}")` }}
              ></div>
              <div className={"sliderContent"}>
                <div className="corner topCorner"></div>
                <h2 className={"title"}>
                  ANGELIKA
                  <br />
                  GRZYB
                </h2>
                <StandardBlock>
                  <TopLink to={RoutePath.KONTAKT}>skontaktuj się</TopLink>
                </StandardBlock>
                <div className="corner botCorner"></div>
              </div>
            </div>
            <div className={"arrowDownBtn"}>
              <img src={ArrowDown} alt="ArrowDown" />
            </div>
          </div>
        </Container>
      </section>
      <div className="showOnMobile">
        <AboutSection />
      </div>
      <GallerySection />
      <div className="hideOnMobile">
        <AboutSection />
      </div>
      <ProcessSection />
      <ContactForm />
      <ContactSection />
    </PageWrapper>
  );
};

export default HomePage;
