import React from "react";
import "./about-section.scss";
import Container from "../../components/container/container";
import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";
import HalfHeroBlock from "../../components/half-hero-block/half-hero-block";
import StandardBlock from "../../components/standard-block/standard-block";

const AboutSection: React.FC<any> = () => {

  const AboutDescription = () => {
    return (
      <StandardBlock>
        <div className="title">O MNIE</div>
        <span className="showOnMobile"><br/><br/></span>

        <p>Jestem Absolwentką studiów pierwszego stopnia Wydziału Architektury na Politechnice Wrocławskiej, które ukończyłam w 2017 r.
          <span className="showOnMobile"><br/><br/></span>
           Z pasji do tworzenia czegoś pięknego oraz posiadając wiedzę w zakresie architektury i budownictwa pozyskaną podczas studiów oraz  praktyki zawodowej, rozpoczęłam w 2020r własną  działalność gospodarczą, pod nazwą firmy  „CZAS NA DESIGN inż.arch Angelika Grzyb”.<br />
          <span className="showOnMobile"><br/></span>
        Główne zalety  współpracy ze mną to  indywidualne podejście do każdego klienta , niezależnie od wielkości zlecenia.</p>
        <TopLink to={RoutePath.OMNIE}>dowiedz się więcej</TopLink>
      </StandardBlock>
    )
  }
  return (
    <section className="about-section">
      <Container>
        <HalfHeroBlock variant="dark" align="left">
          DLA MNIE PROJEKTOWANIE<br />TO NIE PRACA,<br />TO MOJA PASJA
        </HalfHeroBlock>
        <div className="darkPart hideOnMobile">
          <AboutDescription />
        </div>
      </Container>
      <div className="darkPart showOnMobile">
        <br />
        <AboutDescription />
        <br />
      </div>
    </section>
  )
}

export default AboutSection;