import React from "react";
import "./wycena-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
// import StandardBlock from "../../components/standard-block/standard-block";
import CtaBlock from "../../components/cta-block/cta-block";
import { RoutePath } from "../../../route-paths";
import ContactForm from "../../components/contact-form/contact-form";
import Col from "../../components/grid/col";
import Grid from "../../components/grid/grid";
import Row from "../../components/grid/row";

const WycenaPage: React.FC<any> = () => {
  // const SharedDescription = () => {
  //   return (
  //     <StandardBlock>
  //       <p>
  //         Jestem Absolwentką studiów pierwszego stopnia Wydziału Architektury na
  //         Politechnice Wrocławskiej , które ukończyłam w 2017 r. Z pasji do
  //         tworzenia czegoś pięknego oraz posiadając wiedzę w zakresie
  //         architektury i budownictwa pozyskaną podczas studiów oraz praktyki
  //         zawodowej , rozpoczęłam w 2020r własną działalność gospodarczą , pod
  //         nazwą firmy „ CZAS NA DESIGN inż.arch Angelika Grzyb”. Główne zalety
  //         współpracy ze mną to indywidualne podejście do każdego klienta ,
  //         niezależnie od wielkości zlecenia.
  //       </p>
  //     </StandardBlock>
  //   );
  // };

  return (
    <PageWrapper className="omnie-page">
      <Helmet>
        <title>{"Czas na design"}</title>
      </Helmet>
      <Container>
        {/* <HeroBlock bg={Bg} title={"WYCEŃ PROJEKT"}></HeroBlock> */}
        <div className="break"></div>
        <Grid>
          <Row>
            <Col size={[8, 10, 12]} offset={[2, 1, 0]}>
              <ContactForm />
            </Col>
          </Row>
        </Grid>
        <CtaBlock to={RoutePath.KONTAKT}>SKONTAKTUJ SIĘ ZE MNĄ</CtaBlock>
      </Container>
    </PageWrapper>
  );
};

export default WycenaPage;
