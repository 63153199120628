import React from "react";
import "./half-hero-block.scss";

interface Props {
  children: React.ReactNode;
  variant: 'dark' | 'white';
  align: 'left' | 'right';
}

const HalfHeroBlock: React.FC<Props> = (props: Props) => {
  const { children, variant, align } = props;
  return (
    <div className={`half-hero-block half-hero-block--${variant} half-hero-block--${align}`}>
      <div className="corner topCorner"></div>
      <div className="title">{children}</div>
      <div className="corner botCorner"></div>
    </div>
  )
}

export default HalfHeroBlock;