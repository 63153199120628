/* eslint-disable jsx-a11y/anchor-has-content */
import React, { } from "react";
import "./halfImgHalfTextRow.scss";
import FullImgBlock from "../fullImg-block/fullImg-block";
import Col from "../grid/col";
import Row from "../grid/row";

interface Props {
  bg: string,
  children: React.ReactNode;
  isReverseMode?: boolean;
  alt?: string;
}

const HalfImgHalfTextRow: React.FC<Props> = (props: Props) => {

  const { bg, children, isReverseMode, alt } = props;

  return (
    <Row className={`halfImgHalfTextRow${isReverseMode ? " reverseMode" : ""}`}>
      <Col size={6}>
        <FullImgBlock img={bg} alt={alt} />
      </Col>
      <Col size={6}>
        <div className="contentWrapper">
          {children}
        </div>
      </Col>
    </Row>
  )
}

export default HalfImgHalfTextRow;