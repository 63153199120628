/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useRef, useState } from "react";
import "./collapsible-block.scss";
import { Collapse } from 'react-collapse';
import ArrowDownIcon from './../../assets/arrowDown.svg';

interface Props {
  title: string,
  children: React.ReactNode;
  isOpenInit?: boolean;
  isSplited?: boolean
}

const CollapsibleBlock: React.FC<Props> = (props: Props) => {
  const [isOpen, setIsOpen] = useState(props.isOpenInit ? true : false)
  const { title, children, isSplited } = props;
  const ref = useRef<any>();

  const handleClickTitle = () => {
    if (!isOpen) {
      // handleScrollToSection(ref, 110, true)
    }
    setIsOpen(!isOpen);
  }

  return (
    <div ref={ref} className="collapsible-block">
      <div className={`collapsible-block__title${isSplited ? ' isSplited' : ''}`} onClick={() => handleClickTitle()}>
        {/* <img className={`imgIcon${isOpen ? " isOpen" : ""}`} src={ArrowDownIcon} alt="arrow" />
        <div className="title">
          {title}
        </div> */}

        <img className={`imgIcon${isOpen ? " isOpen" : ""}`}  src={ArrowDownIcon} alt="ArrowDown" /><span className="title">{title}</span><span className="offset"></span>

      </div>
      <Collapse isOpened={isOpen} reversed={true}>
        <div className={`collapsible-block__content${isSplited ? ' isSplited' : ''}`}>
          {children}
        </div>
      </Collapse>
    </div>
  )
}

export default CollapsibleBlock;