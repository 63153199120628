import "./App.scss";
import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { History } from 'history';
import CookiePolicyPopup from "./framework/components/cookie-policy-popup/cookie-policy-popup";
import { RoutePath } from "./route-paths";
import HomePage from "./framework/pages/home-page/home-page";
import { compose } from "redux";
import { connect, shallowEqual, useSelector } from "react-redux";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";
import { RulesPage } from "./framework/pages/rules-page/rules-page";
import Navbar from "./framework/components/navbar/navbar";
import { select_is_navbarOpenActive } from "./framework/redux/callbacksState/callbacksState.selectors";
import PreFooter from "./framework/components/prefooter/prefooter";
import SlideOverlay from "./framework/components/slide-overlay/slide-overlay";
import Footer from "./framework/components/footer/footer";
import OmniePage from "./framework/pages/omnie-page/omnie-page";
import RealizacjePage from "./framework/pages/realizacje-page/realizacje-page";
import KontaktPage from "./framework/pages/kontakt-page/kontakt-page";
import OfertaPage from "./framework/pages/oferta-page/oferta-page";
import WycenaPage from "./framework/pages/wycena-page/wycena-page";

interface Props {
  match: any;
  history?: History;
}

const App: React.FC<Props> = () => {
  const is_navbarOpenActive = useSelector(select_is_navbarOpenActive, shallowEqual);

  return (
    <>
      <SlideOverlay />
      <Navbar />
      <div className={`overlay${is_navbarOpenActive ? " isActive" : ""}`}>
        <Switch>
          <Route exact path={RoutePath.RULES} render={() => <RulesPage />} />
          <Route exact path={RoutePath.HOME} render={() => <HomePage />} />
          <Route exact path={RoutePath.OMNIE} render={() => <OmniePage />} />
          <Route exact path={RoutePath.REALIZACJE} render={() => <RealizacjePage />} />
          <Route exact path={RoutePath.KONTAKT} render={() => <KontaktPage />} />
          <Route exact path={RoutePath.OFERTA} render={() => <OfertaPage />} />
          <Route exact path={RoutePath.WYCENA} render={() => <WycenaPage />} />
        </Switch>
        <PreFooter />
        <Footer />
      </div>
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      <CookiePolicyPopup />
    </>
  );
}

export default compose(
  withRouter,
  connect(null, null)
)(App);