


import React, { useEffect, useState } from "react";
import "./slide-overlay.scss";
import history from './../../../history';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { select_is_slideOverlayActive } from "../../redux/callbacksState/callbacksState.selectors";
import { hideSlideOverlay } from "../../redux/callbacksState/callbacksState.actions";

interface Props {
}

const SlideOverlay: React.FC<Props> = (props: Props) => {
  const [isOverlayActive, setIsOverlayActive] = useState(false)
  const [isOpacityActive, setIsOpacityActive] = useState(false)
  const slideOverlayState = useSelector(select_is_slideOverlayActive, shallowEqual);
  const dispatch = useDispatch()

  useEffect(() => {
    if (slideOverlayState.isActive === true) {
      showSlide();
    } else if (slideOverlayState.isActive === false) {
      hideSlide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideOverlayState])

  const showSlide = () => {
    setIsOverlayActive(true)
    setIsOpacityActive(true);
    setTimeout(() => {
      window.scrollTo(0, 0);
      history.push(slideOverlayState.redirectTo as string);
      dispatch(hideSlideOverlay());
    }, 300);
  }

  const hideSlide = () => {
    setIsOpacityActive(false);
    setTimeout(() => {
      setIsOverlayActive(false)
    }, 300);
  }

  return (
    <div className={`overlayBox${isOverlayActive ? ' isActive' : ''}`}>
      <div className={`overlayBoxOpacity${isOpacityActive ? ' isOpacityActive' : ''}`}></div>
    </div>
  )
}

export default SlideOverlay;
