import React, { ReactChild } from "react";
import "./btn-custom.scss";

interface Props {
  children: ReactChild;
  disabled?: boolean;
  className?: string;
}

const BtnCustom: React.FC<any> = (props: Props) => {
  const { children, disabled, className } = props

  return (
    <button className={`customButton${className ? ' ' + className : ''}`} disabled={disabled}>
      <div>{children}</div>
    </button>
  )
}

export default BtnCustom;