import React from "react";
import "./kontakt-page.scss";
import Helmet from "react-helmet";
import Container from "../../components/container/container";
import HeroMapBlock from "../../components/hero-block/hero-map-block";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import ExampleBg from "../../assets/exampleBg.jpg";
import StandardBlock from "../../components/standard-block/standard-block";
import FullMapSection from "../../components/map-section/map-section";
// import { RoutePath } from "../../../route-paths";
// import TopLink from "../../components/top-link/top-link";
import FbIcon from "../../assets/fb.svg";
import HeroBlock from "../../components/hero-block/hero-block";

const KontaktPage: React.FC<any> = () => {

  const ContactBlock = () => {
    return (
      <StandardBlock variant="white">
        <div className="subtitle">ADRES:</div>
        <p>ul. Południowa 1<br />56-400 Oleśnica</p>
        <br />
        <div className="subtitle">TELEFON KONTAKTOWY:</div>
        <p>+48 535 199 564</p>
        <br />
        <div className="subtitle">ADRES E-MAIL:</div>
        <p>biuro@czasnadesign.pl</p>
        <br />
        <div className="subtitle">ZNAJDŹ MNIE:</div>
        <div className="iconsWrapper">
          <a href="https://www.facebook.com/CNDwnetrza?locale=pl_PL"><img src={FbIcon} alt="" /></a>
          {/* <a href="https://instagram.com"><img src={IgIcon} alt="" /></a> */}
        </div>
        <br />
        {/* <TopLink to={RoutePath.KONTAKT} className={"link"} variant={"dark"}>NAPISZ JUŻ TERAZ!</TopLink> */}
      </StandardBlock>
    )
  }

  return (
    <PageWrapper className="kontakt-page">
      <Helmet>
        <title>{'Czas na design'}</title>
      </Helmet>

      <section>
        <Container>
          <div className="hideOnMobile">
            <HeroMapBlock bg={ExampleBg} title={"KONTAKT"} isCollapsible={true}>
              <ContactBlock />
            </HeroMapBlock>
          </div>
          <div className="showOnMobile">
            <HeroBlock bg={ExampleBg} title={"KONTAKT"} isCollapsible={false}></HeroBlock>
            <div className="darkBlock">
              <ContactBlock />
            </div>
            <FullMapSection />
          </div>
          <br />
          <br />
          <br />
        </Container>
      </section>
    </PageWrapper>
  )
}

export default KontaktPage;


